import styles from './index.module.scss'
import {useEffect, useMemo, useState} from "react";
import Button from "../../common/button";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import ReactSlider from "react-slider";
import {fetchBrands} from "../../../redux/action-creaters/brands";
import Input from "../../common/input";
import {Api} from "../../../api";
import PopupFormWrapper from "../popupFormWrapper";
import Selector from "./selector";

export default function Assortment() {
    const [step, setStep] = useState(0);
    const [results, setResults] = useState({step2: 500});
    const dispatch = useDispatch();

    const {items} = useSelector(state => state.brands)

    useEffect(() => {
        if (items.length === 0) {
            dispatch(fetchBrands())
        }
    }, [])

    const isNextDisabled = useMemo(() => {
        if (step === 0 && !results.step0) {
            return true
        }
        if (step === 1 && !results.step1) {
            return true
        }
        if (step === 2 && !results.step2) {
            return true
        }
        if (step === 3 && !results.step3) {
            return true
        }
        if (step === 4 && !results.step4) {
            return true
        }
        if (step === 5) {
            return  true
        }
        return false
    }, [step, results])



    const [fields, setFields] = useState({});
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const masks = {
        'Телефон': '+9 (999) 999 99-99'
    }

    const submit = async () => {
        setIsProcessing(true)
        const data = await Api.forms.quiz({...result, form_textarea_48: JSON.stringify(results)});
        if (data.success) {
            setSuccess(`Заявка отправлена. Номер ващей завяки #${data.success}`)
        } else if (data.message) {
            setError(data.message)
            setFields(data.fields)
        }
        setIsProcessing(false)
    }

    useEffect(async () => {
        setLoading(true)
        setFields(await Api.forms.quiz({getFields: true}));
        setLoading(false)
    }, [])

    return (
        <div className={styles.Content}>
            <div>Пройдите опрос и получите подходящий фаркоп</div>
            <div>
                {step === 0 && (
                    <div>
                        <h3>1. Выберите ваш автомобиль</h3>

                        <Selector onSelect={(car) => {
                            setResults({ ...results, step0: car})
                        }}/>
                    </div>
                )}
                {step === 1 && (
                    <div>
                        <h3>2. Зачем Вам нужен фаркоп</h3>

                        <div className={styles.Variants}>
                            {['Для перевозки прицепов с грузом', 'Для перевозки водного транспорта', 'Для перевозки автодомов'].map(item => (
                                <span className={clsx(results.step1 === item && styles.Selected)} key={item} onClick={() => {
                                    setResults({ ...results, step1: item})
                                }}>{item}</span>
                            ))}
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div>
                        <h3>3. Примерная тяговая нагрузка</h3>

                        <ReactSlider
                            className={styles.Slider}
                            thumbClassName={styles.Thumb}
                            trackClassName={'trackNew'}
                            value={[
                                results.step2 || 500
                            ]}
                            min={0}
                            max={1000}
                            pearling
                            renderThumb={(props) => <div {...props}><span>{props['aria-valuenow']}</span></div>}
                            onAfterChange={data => {
                                setResults({ ...results, step2: data})
                            }}
                        />
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <h3>4. Пожелание к производителю</h3>

                        <div className={styles.SectionOneBrand}>
                            {items?.map(brand => (
                                <span className={clsx(results.step3 === brand.name && styles.Selected)} key={brand.name} onClick={() => {
                                    setResults({ ...results, step3: brand.name})
                                }}>
                                    <div>
                                        <img src={brand.pictureSrc}/>
                                    </div>
                                    <span>{ brand.name }</span>
                                </span>
                            ))}
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div>
                        <h3>5. Нужна ли установка?</h3>

                        <div className={styles.Variants}>
                            {['Да', 'Нет', 'Не уверен'].map(item => (
                                <span className={clsx(results.step4 === item && styles.Selected)} key={item} onClick={() => {
                                    setResults({ ...results, step4: item})
                                }}>{item}</span>
                            ))}
                        </div>
                    </div>
                )}
                {step === 5 && (
                    <div>
                        <h3>6. Контакты</h3>
                        <PopupFormWrapper
                            isLoading={isLoading}
                            isProcessing={isProcessing}
                            onSubmit={submit}
                            errorText={error}
                            successText={success}
                        >
                            {Object.values(fields).filter(item => {
                                return item.CODE != "form_textarea_48"
                            }).map(({NAME, CODE, REQUIRED, ERROR}) => (
                                <Input
                                    key={CODE}
                                    label={NAME}
                                    required={REQUIRED}
                                    mask={masks[NAME]}
                                    onChange={(value) => setResult({...result, [CODE]: value})}
                                    isError={ERROR}
                                />
                            ))}
                        </PopupFormWrapper>
                    </div>
                )}
            </div>

            <div className={styles.Bottom}>
                <Button label={'Назад'} type={'outline'} isDisabled={step === 0} onClick={() => {
                    setStep(step - 1)
                }}/>
                <Button label={'Далее'} onClick={() => {
                    setStep(step + 1)
                }} isDisabled={isNextDisabled}/>
            </div>
        </div>
    )
}
