import {useMemo} from "react";
import styles from './index.module.scss'
import Sldier from '/components/common/slider'

export default function Images({images, video, alt}) {
    const videosFormated = useMemo(() => {
        if (video) {
            let videos = video;
            if (!Array.isArray(videos)) {
                videos = [videos];
            }

            return videos.map(video => {
                let videoCode = video.match(/[?&]v=([^&]*)/);
                if (!videoCode || !videoCode[1]) {
                    videoCode = video.match(/be[/]*(.+)/);
                }
                if (videoCode && videoCode[1]) {
                    return {
                        thumb: `https://img.youtube.com/vi/${videoCode[1]}/default.jpg`,
                        full: `https://www.youtube.com/embed/${videoCode[1]}?rel=0&amp;autoplay=1`,
                        isVideo: true,
                    }
                }
                return null
            }).filter(video => video)

        }
        return []
    }, [video])

    const imagesFormated = useMemo(() => {
        if (videosFormated.length > 0) {
            return [
                images[0],
                videosFormated[0],
                ...images.slice(1),
                ...videosFormated.slice(1)
            ]
        }
        return images
    }, [images, videosFormated])

    return (
        <div className={styles.Images}>
            {imagesFormated.length > 0 ? (
                <Sldier images={imagesFormated} alt={alt}/>
            ) : (
                <img src='/no_photo.jpeg'/>
            )}
        </div>
    )
}