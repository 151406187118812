import Input from "../../common/input";
import {useEffect, useMemo, useState} from "react";
import {Api} from "/api";
import {useDispatch, useSelector} from "react-redux";
import PopupFormWrapper from "../popupFormWrapper"
import styles from "./index.module.scss";
import clsx from "clsx";

export default function OrderInstall() {
    const [fields, setFields] = useState({});
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const {shops} = useSelector(state => state.location)
    const {contacts} = useSelector(state => state.contacts)

    const shopsFiltered = useMemo(() => {
        return Object.values(shops).filter(({MEDIA_SRC, TYPE}) => TYPE && MEDIA_SRC?.length > 0).map(shop => {
            let call_touch;
            if (contacts && Array.isArray(contacts)) {
                contacts.map(contact => {
                    if (shop.NAME.indexOf(contact.NAME) > -1) {
                        call_touch = contact.CALL_TOUCH_CODE
                    }
                })
            }
            return {
                call_touch,
                ...shop
            }
        })
    }, [shops, contacts])

    const shopsFilteredHost = useMemo(() => {
        if (typeof window !== 'undefined') {
            return shopsFiltered.filter(shop => {
                if (window.location.host === 'localhost:3000') {
                    return true;
                }

                if (window.location.host === 'farkop.ru' || window.location.host === 'demo.farkop.ru' || window.location.host === 'farkop-msk.ru') {
                    return shop['NAME'].includes('Москва');
                }
                if (window.location.host === 'spb.farkop.ru' || window.location.host === 'spb.farkop-msk.ru') {
                    return shop['NAME'].includes('Санкт-Петербург');
                }
            });
        }
        return shopsFiltered
    }, [shopsFiltered]);

    const masks = {
        'Телефон': '+9 (999) 999 99-99'
    }

    const submit = async () => {
        setIsProcessing(true)
        const data = await Api.forms.orderInstall(result);
        if (data.success) {
            setSuccess('Заявка отправлена.')
        } else if (data.message) {
            setError(data.message)
            setFields(data.fields);
        }
        setIsProcessing(false)
    }

    const {data} = useSelector(state => state.popup)

    useEffect(async () => {
        setLoading(true)
        setFields(await Api.forms.orderInstall({getFields: true}));
        setResult({form_text_31: data});
        setLoading(false)
    }, [])

    return (
        <PopupFormWrapper
            title='Заказать установку'
            isLoading={isLoading}
            isProcessing={isProcessing}
            onSubmit={submit}
            errorText={error}
            successText={success}
        >
            {Object.values(fields).map(({NAME, CODE, REQUIRED, ERROR, TYPE}) => (
                <>
                    {CODE === 'form_text_31' ? (
                        <div className={styles.Input}>
                            <label>{NAME}</label>

                            <select
                                className={styles.Select}
                                value={result[CODE]}
                                onChange={event => setResult({...result, [CODE]: event.target.value})}
                            >
                                {shopsFilteredHost.map(shop => (
                                    <option key={shop.ID} value={shop.NAME}>{shop.NAME}</option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        <Input
                            key={CODE}
                            label={NAME}
                            required={REQUIRED}
                            mask={masks[NAME]}
                            onChange={(value) => setResult({...result, [CODE]: value})}
                            isError={ERROR}
                            isMultiple={TYPE === 'textarea'}
                            value={result[CODE]}
                        />
                    )}
                </>
            ))}
        </PopupFormWrapper>
    )
}
