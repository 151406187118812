import styles from './index.module.scss'
import {useEffect, useState, useMemo} from "react";
import {useSelector} from "react-redux";
import {Api} from "/api";

export default function Selector({onSelect}) {
    const {marks} = useSelector(state => state.models)
    const [selectedMark, setSelectedMark] = useState(0);
    const [selectedModel, setSelectedModel] = useState(0);
    const [selectedType, setSelectedType] = useState(0);
    const [models, setModels] = useState([]);
    const [types, setTypes] = useState([]);
    const [loadState, setLoadState] = useState(true);
    const [isLoading, setLoading] = useState(false);

    const steps = {
        mark: 'mark',
        model: 'model',
        type: 'type',
        section: 'section'
    }

    const currentStep = useMemo(() => {
        if (!selectedMark) {
            return steps.mark
        }
        if (!selectedModel) {
            return steps.model
        }
        if (!selectedType) {
            return steps.type
        }
        return steps.section
    }, [selectedMark, selectedModel, selectedType]);


    useEffect(async () => {
        setLoadState(false)
        setLoading(false)
    }, [])

    useEffect(async () => {
        if (selectedMark == 0) {
            return
        }
        setLoading(true)

        setModels(await Api.models.getModelsByMarkId(selectedMark, 'ID,NAME,CODE'))

        if (!loadState) {
            setSelectedModel(0);
            setSelectedType(0);
            setLoading(false);
        }


    }, [selectedMark]);

    useEffect(() => {
        if (models.length === 1) {
            setSelectedModel(models[0].ID);
        }
    }, [models]);

    useEffect(async () => {
        if (selectedModel == 0) {
            return
        }
        setLoading(true)
        setTypes(await Api.models.getTypesByModelId(selectedModel, 'ID,NAME,SECTIONS,CODE'));

        if (!loadState) {
            setSelectedType(0);
            setLoading(false);
        }
    }, [selectedModel]);

    useEffect(() => {
        if (types.length === 1) {
            setSelectedType(types[0].ID);
        }
    }, [types]);

    useEffect(async () => {
        if (selectedType) {
            const currentMark = selectedMark ? marks.find(item => item.ID == selectedMark) : null;
            const currentModel = selectedModel ? models.find(item => item.ID == selectedModel) : null;
            const currentType = selectedType ? types.find(item => item.ID == selectedType) : null;
            onSelect(`${currentMark?.NAME} ${currentModel?.NAME} ${currentType?.NAME}`)
        }
    }, [selectedType, types]);

    return (
        <div className={styles.Selector} id="selector">
            <div className={styles.Top} data-type={currentStep}>
                <div>
                    <div>Марка</div>
                    <select
                        className={styles.Select}
                        value={selectedMark}
                        onChange={event => setSelectedMark(event.target.value)}
                        data-value={selectedMark}
                        >
                        <option value='0' disabled>Выберите марку</option>
                        {marks.map(mark => (
                            <option key={mark.ID} value={mark.ID}>{ mark.NAME }</option>
                        ))}
                    </select>
                </div>
                {selectedMark > 0 && (
                    <div>
                        <div>Модель</div>
                        <select
                            className={styles.Select}
                            value={selectedModel}
                            onChange={event => setSelectedModel(event.target.value)}
                            data-value={selectedModel}
                            >
                            <option value='0' disabled>Выберите модель</option>
                            {models.map(model => (
                                <option key={model.ID} value={model.ID}>{ model.NAME }</option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedModel > 0 && (
                    <div>
                        <div>Кузов</div>
                        <select
                            className={styles.Select}
                            value={selectedType}
                            onChange={event => setSelectedType(event.target.value)}
                            data-value={selectedType}
                            >
                            <option value='0' disabled>Выберите кузов</option>
                            {types.map(type => (
                                <option key={type.ID} value={type.ID}>{ type.NAME }</option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        </div>
    )
}