import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async getProductByCode(Redis, code, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/product/getByCode?code=${code}`, reset);
    },
    async getBuyWithThis(ids) {
        const {data} = await axios.get(
            `${config.API_URL}/product/getBuyWithThis?ids=${ids.join(',')}`,
        );
        return data;
    },
});