import styles from './index.module.scss'
import {useDispatch} from "react-redux";
import Icon from "../../../common/icon";

export default function Files({file, isBaltex}) {
    const dispatch = useDispatch();
    return file && <div className={styles.Files}>
        <a href={file} className={styles.File} rel="noreferrer" target="_blank">
            <Icon fill='#1247a5' id={isBaltex ? 'baltexpdf' : 'pdf'} width={18} height={20}/>
            <div className={styles.Name}>
                <div>Инструкция</div>
            </div>
        </a>
    </div>
}