import {redisGetHandler} from "../utils/redis";

export default (axios: any, config: any) => ({
    async getSectionCodes(Redis: any, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getSectionCodes`, reset);
    },
    async getSectionProductCodes(Redis: any, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getSectionProductCodes`, reset);
    },
    async getSubSectionCodes(Redis: any, sectionCode: string, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getSubSectionCodes?sectionCode=${sectionCode}`, reset);
    },
    async getSections(Redis: any, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getSections`, reset);
    },
    async getProducts(Redis: any, sectionCode: string, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getProducts?sectionCode=${sectionCode}`, reset);
    },
    async getSection(Redis: any, sectionCode: string, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getSection?sectionCode=${sectionCode}`, reset);
    },
    async getSectionModels(Redis: any, sectionCode: string, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getSectionModels?sectionCode=${sectionCode}`, reset);
    },
    async getSectionFilters(Redis: any, sectionCode: string, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getSectionFilters?sectionCode=${sectionCode}`, reset);
    },
    async getBrands(Redis: any, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getBrands`, reset);
    },
    async getShops(Redis: any, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getShops`, reset);
    },
    async getServices(Redis: any, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getServices`, reset);
    },
    async getNews(Redis: any, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/sitemap_v2/getNews`, reset);
    },
});
  