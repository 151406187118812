import Input from "../../common/input";
import {useEffect, useState} from "react";
import {Api} from "../../../api";
import {useSelector} from "react-redux";
import PopupFormWrapper from "../popupFormWrapper"

export default function FindLowPrice() {
    const [fields, setFields] = useState({});
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const {data} = useSelector(state => state.popup)
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const masks = {
        'Телефон': '+9 (999) 999 99-99'
    }

    const submit = async () => {
        setIsProcessing(true)
        const data = await Api.forms.findCheaper(result);
        if (data.success) {
            setSuccess(`Заявка отправлена. Номер ващей завяки #${data.success}`)
        } else if (data.message) {
            setError(data.message)
        }
        setIsProcessing(false)
    }

    useEffect(async () => {
        setLoading(true)
        setFields(await Api.forms.findCheaper({getFields: true}))
        setResult({form_text_29: data.name});
        setLoading(false)
    }, [])

    return (
        <PopupFormWrapper
            title='Нашли дешевле'
            isLoading={isLoading}
            isProcessing={isProcessing}
            onSubmit={submit}
            errorText={error}
            successText={success}
        >
            {Object.values(fields).map(({NAME, CODE, REQUIRED, ERROR}) => (
                <Input
                    key={CODE}
                    label={NAME}
                    required={REQUIRED}
                    mask={masks[NAME]}
                    onChange={(value) => setResult({...result, [CODE]: value})}
                    isError={ERROR}
                    value={result[CODE]}
                />
            ))}
        </PopupFormWrapper>
    )
}
