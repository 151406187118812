import Input from "../../common/input";
import {useEffect, useState} from "react";
import {Api} from "/api";
import PopupFormWrapper from "../popupFormWrapper"

export default function FeedBack() {
    const [fields, setFields] = useState({});
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const masks = {
        'Телефон': '+9 (999) 999 99-99'
    }

    const submit = async () => {
        setIsProcessing(true)
        const data = await Api.forms.feedBack(result);
        if (data.success) {
            setSuccess(`Обращение отправлено. Номер обращения #${data.success}`)
        } else if (data.message) {
            setError(data.message)
            setFields(data.fields)
        }
        setIsProcessing(false)
    }

    useEffect(async () => {
        setLoading(true)
        setFields(await Api.forms.feedBack({getFields: true}));
        setLoading(false)
    }, [])

    return (
        <PopupFormWrapper
            title='Связаться с директором'
            isLoading={isLoading}
            isProcessing={isProcessing}
            onSubmit={submit}
            errorText={error}
            successText={success}
        >
            {Object.values(fields).map(({NAME, CODE, REQUIRED, ERROR, TYPE}) => (
                <Input
                    key={CODE}
                    label={NAME}
                    required={REQUIRED}
                    mask={masks[NAME]}
                    onChange={(value) => setResult({...result, [CODE]: value})}
                    isError={ERROR}
                    isMultiple={TYPE === 'HTML'}
                />
            ))}
        </PopupFormWrapper
>
    )
}
