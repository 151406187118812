export const showPopup = (value, payload = {}) => {
    return (dispatch) => {
        dispatch({type: 'SHOW_POPUP', payload: value})
        dispatch({type: 'SET_POPUP_DATA', payload: payload})
        dispatch({type: 'SET_IS_AGREE', payload: false})
        dispatch({type: 'SET_SHOW_TOP_SECTIONS', payload: false})
    }
}

export const seaAgree = (value) => {
    return (dispatch) => {
        dispatch({type: 'SET_IS_AGREE', payload: value})
    }
}