import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useMemo, useState} from "react";
import Button from '../../common/button';
import {useRouter} from "next/router";

export default function ProductVideos() {
    const {data} = useSelector(state => state.popup)
    const router = useRouter();

    const video = useMemo(() => {
        if (data.video.length > 0) {
            const videoLink = Array.isArray(data.video) ? data.video[0] : data.video;
            let videoCode = videoLink.match(/[?&]v=([^&]*)/);
            if (!videoCode || !videoCode[1]) {
                videoCode = videoLink.match(/be[/]*(.+)/);
            }
            if (videoCode && videoCode[1]) {
                return `https://www.youtube.com/embed/${videoCode[1]}?rel=0&amp;autoplay=1`;
            }
        }
        return false
    }, [data])

    return (
        <div className={styles.Content}>
            <h1>{data.name}</h1>
            <iframe src={video}></iframe>
            <Button label={'Перейти в карточку товара'} type="outline" onClick={() => {
                 router.push(data.link)
            }}/>
        </div>
    )
}
