import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async getTopMarks(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/models/getTopMarks', reset);
    },
    async getTopMarksBaltex(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/models/getTopMarksBaltex', reset);
    },
    async getModels(select) {
        const {data} = await axios.get(`${config.API_URL}/models/getModels?select=${select}`);
        return data;
    },
    async getModelsByMarkId(id, select) {
        const {data} = await axios.get(`${config.API_URL}/models/getModelsByMarkId?id=${id}&select=${select}`);
        return data;
    },
    async getTypesByModelId(id, select) {
        const {data} = await axios.get(`${config.API_URL}/models/getTypesByModelId?id=${id}&select=${select}`);
        return data;
    },
    async getCommonSections() {
        const {data} = await axios.get(`${config.API_URL}/models/getCommonSections`);
        return data;
    },
    async getModelsByMarkIdBaltex(id, select) {
        const {data} = await axios.get(`${config.API_URL}/models/getModelsByMarkIdBaltex?id=${id}&select=${select}`);
        return data;
    },
    async getTypesByModelIdBaltex(id, select) {
        const {data} = await axios.get(`${config.API_URL}/models/getTypesByModelIdBaltex?id=${id}&select=${select}`);
        return data;
    },
    async getCommonSectionsBaltex() {
        const {data} = await axios.get(`${config.API_URL}/models/getCommonSectionsBaltex`);
        return data;
    }
});